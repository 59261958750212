import { Box, BoxProps, styled, Typography } from '@mui/material';
import { BorderedContainer } from './GridContainer';

const Container = styled(BorderedContainer)({
    overflowY: 'auto',
});

const HeaderContainer = styled(Box)(({ theme: { getColor, EColors, spacing, shape }, height }) => ({
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 1000,
    width: '100%',
    boxSizing: 'border-box',
    height: height,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: spacing(2.5),
    paddingRight: spacing(2.5),
    backgroundColor: getColor(EColors.drWhite),
    borderTopLeftRadius: shape.borderRadius,
    borderTopRightRadius: shape.borderRadius,
    overflowY: 'auto',
}));

type TitleledContainerProps = {
    headerHeight?: string | number;
    bodyPadding?: string | number;
    title?: string;
    children: string | React.JSX.Element | React.JSX.Element[];
    rightElement?: React.JSX.Element | null;
    leftElement?: React.JSX.Element | null;
    fullBodyHeight?: boolean;
    borderWidth?: 'default' | 'small';
} & BoxProps;

export default function TitledContainer({
    headerHeight = '60px',
    title = '',
    children,
    bodyPadding = 2.5,
    leftElement = null,
    rightElement = null,
    fullBodyHeight = false,
    borderWidth = 'default',
    ...props
}: TitleledContainerProps) {
    return (
        <Container borderWidth={borderWidth} {...props}>
            <HeaderContainer height={headerHeight}>
                {leftElement ? (
                    leftElement
                ) : (
                    <Typography variant="body1" fontWeight={600}>
                        {title}
                    </Typography>
                )}

                {rightElement}
            </HeaderContainer>
            <Box p={bodyPadding} height={fullBodyHeight ? `calc(100% - (${headerHeight} + 40px))` : 'auto'}>
                {children}
            </Box>
        </Container>
    );
}
