import { useRejectInquiry } from 'hooks/queries/BizlyOS/useInquiryActions';
import { useProposalInquiryStore } from 'providers/proposal-inquiry';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { AcceptedInquiry } from './AcceptedInquiry';
import { DeclinedInquiryForm } from './DeclinedInquiryForm';
import { NewInquiryAction } from './NewInquiryAction';
import { RejectedInquiry } from './RejectedInquiry';

const InquiryStatus = {
    SUBMITTED: 'submitted',
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
    // intermediate states for the decline and accept flow
    DECLINING: 'declining',
    ACCEPTING: 'accepting',
};

export const InquiryManager = () => {
    const { inquiry } = useProposalInquiryStore(state => state.proposalInquiryForm);

    const [status, setStatus] = useState(inquiry?.status);

    const { venueId = '', proposalId = '' } = useParams<{ venueId?: string; proposalId?: string }>();

    const rejectInquiry = useRejectInquiry(venueId, proposalId);

    const handleConfirmDecline = async (data: { rejectionNotes: string; rejectionReason: string }) => {
        try {
            await rejectInquiry.mutate(data);
            setStatus(InquiryStatus.REJECTED);
        } catch (error) {
            console.error('Failed to reject inquiry:', error);
        }
    };

    const handleInitiateAccept = () => {
        setStatus(InquiryStatus.ACCEPTING);
    };

    const handleInitiateDecline = () => {
        setStatus(InquiryStatus.DECLINING);
    };

    const handleBack = () => {
        setStatus(InquiryStatus.SUBMITTED);
    };

    const RenderContent = () => {
        switch (status) {
            case InquiryStatus.ACCEPTED:
            case InquiryStatus.ACCEPTING:
                return <AcceptedInquiry venueId={venueId} proposalId={proposalId} />;

            case InquiryStatus.REJECTED:
                return <RejectedInquiry />;

            case InquiryStatus.DECLINING:
                return <DeclinedInquiryForm onSubmit={handleConfirmDecline} onCancel={handleBack} />;

            default:
                return (
                    <NewInquiryAction
                        handleInitiateAccept={handleInitiateAccept}
                        handleInitiateDecline={handleInitiateDecline}
                    />
                );
        }
    };

    return <RenderContent />;
};
