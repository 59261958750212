import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { APIProvider, AdvancedMarker, InfoWindow, Map, useMap } from '@vis.gl/react-google-maps';
import { venueTypesAPIDict } from 'api/venueTypesApi';
import { QueryParamEnum } from 'constants/queryParams';
import useThemedColor from 'hooks/useThemedColor';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { EColors, getColor } from 'theme';
import { i18n } from 'translation';
import { Column } from 'ui';
import MapPin from '../images/icons/new_venue_map_pin.svg?react';

const MapContainer = styled.div<{ fullMapScale?: boolean }>`
    overflow: hidden;
    height: ${props => (props.fullMapScale ? '500px' : '110vh')};
    position: relative;
    margin-top: -32px;
    width: ${props => (props.fullMapScale ? '100%' : '60vh')};
    max-width: 100vw;
    display: flex;

    @media (min-width: 768px) {
        width: 100%;
    }

    .zIndex2 {
        z-index: 2;
    }

    .zIndex1 {
        z-index: 1;
    }
`;

const bounce = keyframes`
  0%   { transform: scale(1)   translateX(-50%)  translateY(calc(-100% + 5px)); }
  10%  { transform: scale(1)   translateX(-50%)  translateY(calc(-100% + 5px)); }
  30%  { transform: scale(1)   translateX(-50%)  translateY(calc(-100% - 5px)); }
  50%  { transform: scale(1)   translateX(-50%)  translateY(calc(-100% + 5px)); }
  75%  { transform: scale(1)   translateX(-50%)  translateY(calc(-100% + 5px)); }
  100% { transform: scale(1)   translateX(-50%)  translateY(calc(-100% + 5px)); }
`;

const yourSearchLocation = {
    0: {
        numId: 0,
        id: 'search location',
        label: i18n.venue.types.yourSearchLocation,
        pinColor: EColors.venuePinSearchLocation as EColors,
    },
};

const venueTypes = {
    ...yourSearchLocation,
    ...venueTypesAPIDict(),
};

const venueTypeMap = {} as Record<EColors, Array<string>>;

Object.values(venueTypes).forEach(venueType => {
    if (venueType.pinColor in venueTypeMap) {
        venueTypeMap[venueType.pinColor].push(venueType.label);
    } else {
        venueTypeMap[venueType.pinColor] = [venueType.label];
    }
});

const AnimatedPin = styled(MapPin)<{ highlighted: boolean }>`
    transform: translate(-50%, calc(-100% + 5px));
    animation: ${bounce} 2s cubic-bezier(0.28, 0.84, 0.42, 1) infinite;

    ${props => (props.highlighted ? `` : `animation: unset;`)}
    ${props => (props.color ? `color: ${props.color};` : ``)}
`;

type TProps = {
    center?: { lat: number; lng: number };
    venues?: Bizly.Venue[];
    highlightedVenueId?: number;
    pinHighlightedVenueId?: number;
    onPinClick?: (venueId: number) => void;
    className?: string;
    defaultZoom?: number;
    blackPins?: boolean;
    showLegend?: boolean;
    fullMapScale?: boolean;
    showZoomControls?: boolean;
    disableGestureHandling?: boolean;
};

const MAP_KEY = import.meta.env.VITE_APP_GMAPS_KEY;

const VenueTileWrapper = styled(Box)`
    border-radius: 4px;
    cursor: pointer;
    width: 260px;
    margin-left: 5px;
    margin-top: 5px;
`;

const VenueImage = styled.img`
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 4px;
`;

const VenueName = styled.div`
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 2.5;
    flex: 1;
    width: 100px;
`;

const VenueHeadline = styled.div`
    font-size: 14px;
    color: ${getColor(EColors.grey3)};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    --webkit-line-clamp: 2;
    --webkit-box-orient: vertical;
`;

const VenueMap = ({
    center,
    venues = [],
    highlightedVenueId,
    className = '',
    defaultZoom = 12,
    blackPins = false,
    fullMapScale = false,
    showZoomControls = true,
    disableGestureHandling = false,
}: TProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const venuePinColors = useThemedColor();
    const pureBlack = venuePinColors.pureBlack;
    const map = useMap('venues-map');

    const THRESHOLD_DISTANCE = 50;

    const [venuePins, setVenuePins] = useState<Array<Bizly.VenuePin>>([]);
    const [selectedVenue, setSelectedVenue] = useState<Bizly.Venue | null | undefined>(null);

    const handlePinClick = useCallback(
        (venueId: number) => {
            const selected = venues.find(venue => venue.id === venueId);
            setSelectedVenue(selected);
        },
        [venues]
    );

    const viewVenueListingInPopup = useCallback(
        (venueId: number) => {
            const params = new URLSearchParams(location.search);
            params.set(QueryParamEnum.VENUE_ID, venueId.toString());
            navigate({
                pathname: location.pathname,
                search: params.toString(),
            });
        },
        [navigate, location]
    );

    const handleVenueClick = useCallback(
        (venueId: number) => {
            const selected = venues.find(venue => venue.id === venueId);
            if (selected) {
                setSelectedVenue(selected);
                viewVenueListingInPopup(selected.id);
            }
        },
        [venues, viewVenueListingInPopup]
    );

    const calculateDistance = (point1: google.maps.LatLng, point2: google.maps.LatLng) => {
        const R = 6371e3; // Earth's radius in meters
        const toRadians = (deg: number) => (deg * Math.PI) / 180;

        const lat1 = toRadians(point1.lat());
        const lat2 = toRadians(point2.lat());
        const dLat = toRadians(point2.lat() - point1.lat());
        const dLng = toRadians(point2.lng() - point1.lng());

        const a = Math.sin(dLat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLng / 2) ** 2;
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c; // Distance in meters
    };

    useEffect(() => {
        if (!map || venuePins.length <= 1) return;

        const bounds = new google.maps.LatLngBounds();
        const latLngs = venuePins.map(pin => new google.maps.LatLng(pin.lat, pin.lng));

        latLngs.forEach(latLng => bounds.extend(latLng));

        const isWithinViewport = latLngs.every((latLng, index) => {
            if (index === 0) return true;
            return calculateDistance(latLngs[0], latLng) < THRESHOLD_DISTANCE;
        });

        if (!isWithinViewport) {
            map.fitBounds(bounds);
        }
    }, [venuePins, map]);

    useEffect(() => {
        if (venues.length > 0) {
            setVenuePins(
                venues.map(venue => ({
                    id: venue.id,
                    lat: venue.lat,
                    lng: venue.lng,
                    type: venue.type,
                    isCenter: !!venue.isCenter,
                }))
            );
        }
    }, [venues]);

    const mapInitialized = useRef(false);
    useEffect(() => {
        if (map && !mapInitialized.current) {
            map.setOptions({
                disableDefaultUI: true,
                streetViewControl: false,
                zoomControl: showZoomControls,
                mapTypeControl: false,
                scaleControl: false,
                rotateControl: false,
                fullscreenControl: false,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.TOP_RIGHT,
                },
                gestureHandling: disableGestureHandling ? 'none' : 'auto',
            });

            google.maps.event.addListener(map, 'click', (event: google.maps.MapMouseEvent) => {
                event.stop();
            });

            mapInitialized.current = true;
        }
    }, [map]);

    useEffect(() => {
        if (map && highlightedVenueId) {
            const highlightedVenue = venues.find(venue => venue.id === highlightedVenueId);
            if (highlightedVenue) {
                map.panTo({ lat: highlightedVenue.lat, lng: highlightedVenue.lng });
            }
        }
    }, [highlightedVenueId, map, venues, defaultZoom, venuePins]);

    const VenueInfoWindowContent = ({ venue, onClick }: { venue: Bizly.Venue; onClick: (id: number) => void }) => {
        return (
            <VenueTileWrapper onClick={() => onClick(venue.id)}>
                <VenueImage src={venue.imageUrl} alt={venue.name} />
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <VenueName>{venue.name}</VenueName>
                </Box>
                <VenueHeadline>{venue.headline}</VenueHeadline>
            </VenueTileWrapper>
        );
    };

    return (
        <Column className={className}>
            <MapContainer fullMapScale={fullMapScale}>
                <Map id="venues-map" mapId="venues-map" defaultZoom={defaultZoom} defaultCenter={center}>
                    {/* center is the place_id coordinates */}
                    {center && (
                        <AdvancedMarker position={{ lat: center.lat, lng: center.lng }}>
                            <AnimatedPin color={pureBlack} highlighted={true} />
                        </AdvancedMarker>
                    )}

                    {venuePins.map(({ id, lat, lng, isCenter }) => {
                        const isSelected = selectedVenue && lat === selectedVenue.lat && lng === selectedVenue.lng;
                        const color = blackPins || isCenter || isSelected ? pureBlack : venuePinColors.primaryAction;
                        return (
                            <AdvancedMarker key={id} position={{ lat, lng }} onClick={() => handlePinClick(id)}>
                                <AnimatedPin color={color} highlighted={id === highlightedVenueId} />
                            </AdvancedMarker>
                        );
                    })}
                    {selectedVenue && (
                        <InfoWindow
                            position={{ lat: selectedVenue.lat, lng: selectedVenue.lng }}
                            pixelOffset={[-30, -40]}
                            headerDisabled
                        >
                            <IconButton
                                onClick={() => setSelectedVenue(null)}
                                style={{
                                    position: 'absolute',
                                    zIndex: '999',
                                    left: '235px',
                                    stroke: 'darkgrey',
                                    color: 'white',
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <VenueInfoWindowContent venue={selectedVenue} onClick={handleVenueClick} />
                        </InfoWindow>
                    )}
                </Map>
            </MapContainer>
        </Column>
    );
};

const MapProvider = (props: TProps) => {
    return (
        <APIProvider apiKey={MAP_KEY}>
            <VenueMap {...props} />
        </APIProvider>
    );
};

export default MapProvider;
