import { RefObject, useEffect, useState } from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT } from 'utils/header';

export const TAB_HEIGHT = 40;

const Tabs = styled.ul`
    list-style: none;
    display: flex;
    padding: 0;
    position: sticky;
    top: ${HEADER_HEIGHT}px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    z-index: 999;
    height: ${TAB_HEIGHT}px;
`;

const Tab = styled('li')`
    cursor: pointer;
    padding: 10px;
    margin-right: 10px;
    text-transform: uppercase;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};

    &.active {
        border-bottom: 2px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
    }
`;

export type TabType = {
    label: string;
    targetRef: RefObject<HTMLDivElement>;
};

type ScrollTabsProps = {
    tabs: TabType[];
    className?: string;
};

const ScrollTabs = ({ tabs, className }: ScrollTabsProps) => {
    const initialDefaultActiveTab = tabs.length > 0 ? tabs[0].targetRef : null;
    const [activeTab, setActiveTab] = useState<RefObject<HTMLDivElement> | null>(initialDefaultActiveTab);

    const handleTabClick = (targetRef: RefObject<HTMLDivElement>) => {
        if (targetRef.current) {
            setActiveTab(targetRef);
            targetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                let activeTabRef: RefObject<HTMLDivElement> | null = null;
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        activeTabRef = tabs.find(t => t.targetRef.current === entry.target)?.targetRef || null;
                    }
                });
                if (activeTabRef) {
                    setActiveTab(activeTabRef);
                }
            },
            {
                threshold: [0.9],
            }
        );

        tabs.forEach(tab => {
            if (tab.targetRef.current) {
                observer.observe(tab.targetRef.current);
            }
        });

        return () => {
            tabs.forEach(tab => {
                if (tab.targetRef.current) {
                    observer.unobserve(tab.targetRef.current);
                }
            });
        };
    }, [tabs]);

    return (
        <Tabs className={className}>
            {tabs.map(tab => (
                <Tab
                    key={tab.label}
                    onClick={() => handleTabClick(tab.targetRef)}
                    className={activeTab === tab.targetRef ? 'active' : ''}
                >
                    {tab.label}
                </Tab>
            ))}
        </Tabs>
    );
};

export default ScrollTabs;
