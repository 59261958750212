import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { Box, styled } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import IataIcon from 'images/bizlyOS/icons/iataIcon.svg';
import { useProposalInquiryStore } from 'providers/proposal-inquiry';
import { EColors, getColor } from 'theme';
import { tzMoment } from 'utils/moment';

const TIME_FORMAT = 'MMM DD, YYYY - h:mm a';

const BorderedContainer = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    padding: spacing(2.5),
    borderRadius: shape.borderRadius,
    border: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
}));

const InfoContainer = styled(BorderedContainer)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: spacing(1.25),
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    border: 'none',
}));

const Title = styled(Body1)(({ theme: { getColor, EColors, spacing } }) => ({
    paddingBottom: spacing(2.5),
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    fontWeight: 600,
}));

const InsightsContainer = styled(Box)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    display: 'flex',
    padding: spacing(2.5),
    borderRadius: shape.borderRadius,
    backgroundColor: getColor(EColors.bizlyOSWarning, 0.1),
    gap: spacing(1.25),
}));

function TextCard({ title, description }: { title: string; description: string | undefined }) {
    return (
        <Box flex={1} display="flex" flexDirection="column" gap={0.75}>
            <Body2 lineHeight="normal" color={EColors.pureBlack} sx={{ opacity: 0.5 }} fontWeight={500}>
                {title}
            </Body2>
            <Body1>{description || '-'}</Body1>
        </Box>
    );
}

export const Overview = () => {
    const { client, proposal, event, eventSpaces, venue, inquiry } = useProposalInquiryStore(
        state => state.proposalInquiryForm
    );

    const calcGuestsCount = () => {
        const sumOfGuest =
            eventSpaces?.reduce(function (accumulator, currentValue) {
                if (currentValue.requestedGuests) {
                    return accumulator + currentValue.requestedGuests;
                }
                return accumulator;
            }, 0) || 0;
        const eventSpacesCount = eventSpaces?.length || 0;

        return `${Math.ceil(sumOfGuest / eventSpacesCount)}`;
    };

    return (
        <Box display="flex" flexDirection="column" gap={2.5}>
            {proposal?.commissionable && (
                <InfoContainer>
                    <img src={IataIcon} alt="IATA Icon" />
                    <Box flex={1}>
                        <Body1 fontWeight={600}>Commissionable Event</Body1>
                        <Body2 fontWeight={600}>
                            IATA Number: <span style={{ fontWeight: '400' }}>33-7 4226 2</span>
                        </Body2>
                    </Box>
                    <Button variant="text" sx={{ padding: 0 }}>
                        Terms
                    </Button>
                </InfoContainer>
            )}
            <BorderedContainer>
                <Title>Client Details</Title>
                <Box display="flex">
                    <TextCard title="Name" description={`${client?.firstName} ${client?.lastName}`} />
                    <TextCard title="Company" description={client?.companyName} />
                </Box>
                <InsightsContainer>
                    <TipsAndUpdatesOutlinedIcon fontSize="small" sx={{ color: getColor(EColors.bizlyOSWarning) }} />
                    <Body2>{`${client?.companyName} is a highly active Bizly client that typically books the same venue multiple times annually. `}</Body2>
                </InsightsContainer>
            </BorderedContainer>

            <BorderedContainer>
                <Title>Event Details</Title>
                <Box display="flex">
                    <TextCard title="Title" description={event?.name} />
                    <TextCard title="Group Size" description={calcGuestsCount()} />
                </Box>
                <Box display="flex">
                    <TextCard
                        title="Start"
                        description={tzMoment(event?.startsAt, venue?.timeZone).format(TIME_FORMAT)}
                    />
                    <TextCard title="End" description={tzMoment(event?.endsAt, venue?.timeZone).format(TIME_FORMAT)} />
                </Box>
            </BorderedContainer>

            {inquiry?.datesFlexible && (
                <BorderedContainer>
                    <Title>Date Flexibility</Title>
                    <Body1>{inquiry?.notes || '-'}</Body1>
                </BorderedContainer>
            )}
        </Box>
    );
};
