import React from 'react';
import styled from 'styled-components';
import { TStyledTheme } from 'theme';
import { i18n } from 'translation';
import { EColors } from 'types/themenum';

export const statusColors = {
    total: EColors.totalAttendee,
    'not invited': EColors.notInvitedAttendee,
    'not sent': EColors.notInvitedAttendee, // Related to the attendee status being enumerable on the database; this is a workaround
    invited: EColors.invitedAttendee,
    attending: EColors.attendingAttendee,
    'not attending': EColors.notAttendingAttendee,
    roomBlocks: EColors.roomBlocks,

    // Survey Response Status
    sent: EColors.surveysSentTotal,
    pending: EColors.surveysPending,
    responded: EColors.surveysRespondedTo,
};

export const statusMap: Record<BizlyAPI.AttendeeStatus, string> = {
    // This is a workaround to bypass attendee statuses being enumerable values and not easily modified
    'not sent': i18n.guests.notSent,
    invited: i18n.guests.invited,
    attending: i18n.guests.attending,
    'not attending': i18n.guests.declined,
};

export const statusOptions = Object.entries(statusMap).map(([status, statusLabel]) => ({
    id: status,
    label: statusLabel,
}));

type AttendanceTypeMap = {
    'In-Person': 'In-Person';
    Virtual: 'Virtual';
    [key: string]: string;
};

export const attendanceTypeMap: AttendanceTypeMap = {
    'In-Person': 'In-Person',
    Virtual: 'Virtual',
};

export const attendanceTypeOptions = Object.keys(attendanceTypeMap).map(attendanceType => ({
    id: attendanceType,
    label: attendanceTypeMap[attendanceType],
}));

const iconStyledFn = ({
    inverted,
    colorOverride,
    theme: { getColor, EColors },
}: {
    inverted?: boolean;
    colorOverride?: EColors;
    theme: TStyledTheme;
}) => ({
    color: getColor(
        colorOverride ? colorOverride : inverted ? EColors.invertedDisplayIcon : EColors.primaryDisplayIcon
    ),
    '> path': {
        fill: getColor(
            colorOverride ? colorOverride : inverted ? EColors.invertedDisplayIcon : EColors.primaryDisplayIcon
        ),
    },
});
const interactibleIconStyledFn = ({
    inverted,
    warning,
    theme,
}: { inverted?: boolean | undefined; warning?: boolean | undefined; theme: TStyledTheme } & {
    inverted?: boolean;
    warning?: boolean;
}) => ({
    ...iconStyledFn({ inverted, theme }),
    ...(!inverted
        ? {
              color: theme.getColor(theme.EColors.primaryAction),
              '> path': {
                  fill: theme.getColor(theme.EColors.primaryAction),
              },
              '&:hover': {
                  color: theme.getColor(theme.EColors.primaryActionHover),
                  '> path': {
                      fill: theme.getColor(theme.EColors.primaryActionHover),
                  },
              },
          }
        : {}),
    ...(warning && {
        color: theme.getColor(theme.EColors.warningAction),
        '> path': {
            fill: theme.getColor(theme.EColors.warningAction),
        },
        '&:hover': {
            color: theme.getColor(theme.EColors.warningActionHover),
            '> path': {
                fill: theme.getColor(theme.EColors.warningActionHover),
            },
        },
    }),
    cursor: 'pointer',
});

export const withIconStyles = <P extends object, T extends React.ComponentType<P>>(icon: T) =>
    styled(icon)(iconStyledFn);

export const withInteractibleIconStyles = <P extends object, T extends React.ComponentType<P>>(icon: T) =>
    styled(icon)(interactibleIconStyledFn);

export const contentWidth = '700px';

type CopyFontSizes = {
    xlarge: string;
    large: string;
    default: string;
    regular: string;
    small: string;
    xsmall: string;
    [key: string]: string;
};

export const copyFontSizes: CopyFontSizes = {
    xlarge: '20px',
    large: '18px',
    default: '15px',
    regular: '15px',
    small: '13px',
    xsmall: '11px',
};

export const OnlySingleLine = `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ROLE_OPTIONS = [
    { id: 'Sales', name: i18n.authFields.sales },
    { id: 'Marketing', name: i18n.authFields.marketing },
    { id: 'Engineering', name: i18n.authFields.engineering },
    { id: 'Business Development', name: i18n.authFields.businessDevelopment },
    { id: 'HR', name: i18n.authFields.hr },
    { id: 'Other', name: i18n.authFields.other },
] as const;

export const DEPARTMENT_OPTIONS = [
    { id: 'Sales & Marketing', name: i18n.authFields.salesMarketing },
    { id: 'Catering / Food & Beverage', name: i18n.authFields.cateringFoodBeverage },
    { id: 'Operations', name: i18n.authFields.operations },
    { id: 'Guest Services', name: i18n.authFields.guestServices },
    { id: 'Other', name: i18n.authFields.other },
] as const;

export const BIZLYOS_CONTACT_TYPES = [
    { id: 'Primary', name: 'Primary' },
    { id: 'Secondary', name: 'Secondary' },
    { id: 'Finance', name: 'Finance' },
    { id: 'General Manager', name: 'General Manager' },
    { id: 'Catering', name: 'Catering' },
    { id: 'Guest Client Services', name: 'Guest / Client Services' },
] as const;

export const defaultUserImageUrl = 'https://res.cloudinary.com/hdd626jg7/image/upload/stencil/icons/default-avatar.png';

export const AttendeeStatus: Record<string, BizlyAPI.AttendeeStatus> = {
    notInvited: 'not invited',
    notSent: 'not sent',
    invited: 'invited',
    attending: 'attending',
    notAttending: 'not attending',
} as const;

export const PostHogFeatureFlags = {
    toggleViewAddedListings: 'toggleViewAddedListings',
    toggleNewCompareProposals: 'toggleNewCompareProposals',
    venuePagination: 'toggleVenuesSearchPagination',
    workspacesEnabled: 'toggleWorkspaces',
    toggleRedesignedProposalPage: 'toggleRedesignedProposalPage',
    eventBudgetApproval: 'eventBudgetApproval',
    toggleNewEventHeader: 'toggleNewEventHeader',
} as const;
