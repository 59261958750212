import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGridPro, GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { GridContainer } from 'components/BizlyOS/GridContainer';
import { ProposalInquiriesToolBar } from 'components/BizlyOS/ProposalInquiriesToolBar';
import { Body2 } from 'components/BizlyOS/Typography/Typography';
import { PageHeadline } from 'components/Ui-V2/Headline/Headline';
import { useProposalsQuery } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { i18n } from 'translation';
import { tzMoment, userTimeZone } from 'utils/moment';

declare global {
    type StatusType =
        | 'All'
        | 'New Inquiry'
        | 'Proposal Sent'
        | 'Won'
        | 'Lost'
        | 'Inquiry Rejected'
        | 'Inquiry Withdrawn'
        | 'Proposal Withdrawn'
        | 'Proposal Expired'
        | 'Inquiry Expired';
}

interface ProposalRow {
    id: string;
    inquiryId: string;
    dateReceived: string;
    lastContact: string;
    customer: string;
    company: string;
    event: string;
    eventStartDate: string;
    eventEndDate: string;
    guestsRoomNights: number;
    eventSpaces: number;
    status: StatusType;
    venueId: number;
    venueName: string;
}

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
    flexGrow: 1,
    '& .MuiDataGrid-root': {
        border: 'none',
    },
    '& .MuiToggleButtonGroup-root': {
        margin: theme.spacing(0.5, 0),
    },
    '& .MuiDataGrid-columnHeader': {
        borderRight: 'none',
        backgroundColor: theme.palette.grey[20],
    },
    '& .MuiDataGrid-row': {
        cursor: 'pointer',
    },
    '& .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
}));

interface StatusBoxProps {
    bg: string;
    text: string;
}

const StatusBox = styled(Box)<StatusBoxProps>(({ bg, text }) => ({
    backgroundColor: bg,
    color: text,
    borderRadius: '.75rem',
    padding: '.125rem .5rem',
    fontSize: '.75rem',
    fontWeight: 'medium',
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: 1.5,
}));

const columns: GridColDef[] = [
    {
        field: 'inquiryId',
        renderHeader: () => (
            <Body2 fontWeight={500} px="10px">
                Inquiry ID
            </Body2>
        ),
        width: 100,
        renderCell: params => <Box padding="0 10px">{params.value}</Box>,
    },
    {
        field: 'dateReceived',
        renderHeader: () => (
            <Body2 fontWeight={500} px="10px">
                Date Received
            </Body2>
        ),
        width: 120,
        renderCell: params => (
            <Box padding="0 10px">{tzMoment(params.value, 'UTC').tz(userTimeZone).format('MMM D, YYYY')}</Box>
        ),
    },
    {
        field: 'lastContact',
        renderHeader: () => (
            <Body2 fontWeight={500} px="10px">
                Last Contact
            </Body2>
        ),
        width: 120,
        renderCell: params => (
            <Box padding="0 10px">{tzMoment(params.value, 'UTC').tz(userTimeZone).format('MMM D, YYYY')}</Box>
        ),
    },
    {
        field: 'customer',
        renderHeader: () => (
            <Body2 fontWeight={500} px="10px">
                Customer
            </Body2>
        ),
        width: 180,
        renderCell: params => <Box padding="0 10px">{params.value}</Box>,
    },
    {
        field: 'company',
        renderHeader: () => (
            <Body2 fontWeight={500} px="10px">
                Company
            </Body2>
        ),
        flex: 0.7,
        minWidth: 150,
        renderCell: params => <Box padding="0 10px">{params.value}</Box>,
    },
    {
        field: 'venueName',
        renderHeader: () => (
            <Body2 fontWeight={500} px="10px">
                Venue
            </Body2>
        ),
        flex: 1,
        minWidth: 200,
        renderCell: params => <Box padding="0 10px">{params.value}</Box>,
    },
    {
        field: 'event',
        renderHeader: () => (
            <Body2 fontWeight={500} px="10px">
                Event
            </Body2>
        ),
        flex: 1,
        minWidth: 200,
        renderCell: params => <Box padding="0 10px">{params.value}</Box>,
    },
    {
        field: 'eventStartDate',
        renderHeader: () => (
            <Body2 fontWeight={500} px="10px">
                Start
            </Body2>
        ),
        width: 180,
        renderCell: params => (
            <Box padding="0 10px">{tzMoment(params.value, userTimeZone).format('MMM D, YYYY h:mma')}</Box>
        ),
    },
    {
        field: 'eventEndDate',
        renderHeader: () => (
            <Body2 fontWeight={500} px="10px">
                End
            </Body2>
        ),
        width: 180,
        renderCell: params => (
            <Box padding="0 10px">{tzMoment(params.value, userTimeZone).format('MMM D, YYYY h:mma')}</Box>
        ),
    },
    {
        field: 'guestsRoomNights',
        renderHeader: () => (
            <Body2 fontWeight={500} px="10px">
                Room Nights
            </Body2>
        ),
        width: 120,
        renderCell: params => <Box padding="0 10px">{params.value}</Box>,
    },
    {
        field: 'eventSpaces',
        renderHeader: () => (
            <Body2 fontWeight={500} px="10px">
                Event Spaces
            </Body2>
        ),
        width: 120,
        renderCell: params => <Box padding="0 10px">{params.value}</Box>,
    },
    {
        field: 'status',
        renderHeader: () => (
            <Body2 fontWeight={500} px="10px">
                Status
            </Body2>
        ),
        width: 150,
        renderCell: params => {
            const getStatusColor = (status: string) => {
                switch (status) {
                    case 'New Inquiry':
                        return { bg: '#FFF9C4', text: '#000000' };
                    case 'Proposal Sent':
                        return { bg: '#E3F2FD', text: '#000000' };
                    case 'Confirmed':
                        return { bg: '#E8F5E9', text: '#000000' };
                    case 'Won':
                        return { bg: '#E8F5E9', text: '#000000' };
                    case 'Lost':
                        return { bg: '#FFEBEE', text: '#000000' };
                    case 'Payment Due':
                        return { bg: '#F3E5F5', text: '#000000' };
                    default:
                        return { bg: '#F5F5F5', text: '#000000' };
                }
            };

            const { bg, text } = getStatusColor(params.value);

            return (
                <Box padding="0 10px">
                    <StatusBox bg={bg} text={text}>
                        {params.value}
                    </StatusBox>
                </Box>
            );
        },
    },
];

export const ProposalInquiries = () => {
    const [filter, setFilter] = useState<StatusType>('All');
    const { rows, isLoading } = useProposalsQuery();

    const navigate = useNavigate();

    const handleFilterChange = useCallback((newFilter: StatusType) => {
        setFilter(newFilter);
    }, []);

    const handleRowClick = (params: GridRowParams<ProposalRow>) => {
        navigate(`/os/venues/${params.row.venueId}/proposals/${params.row.id}/inquiry-overview`);
    };

    const filteredRows = useMemo(() => {
        if (filter === 'All') {
            return rows;
        } else {
            return rows.filter(row => row.status === filter);
        }
    }, [rows, filter]);

    return (
        <GridContainer>
            <PageHeadline withDescription bold>
                {i18n.proposals.inquiries}
            </PageHeadline>
            <StyledDataGridPro
                rows={filteredRows}
                columns={columns}
                loading={isLoading}
                rowHeight={50}
                onRowClick={handleRowClick}
                initialState={{
                    pinnedColumns: { left: ['status'] },
                }}
                slots={{
                    toolbar: ProposalInquiriesToolBar,
                }}
                slotProps={{
                    toolbar: {
                        onFilterChange: handleFilterChange,
                    },
                }}
            />
        </GridContainer>
    );
};
