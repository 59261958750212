import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, FormControl, InputLabel, MenuItem, Select, styled } from '@mui/material';
import React from 'react';
import { FieldError } from 'react-hook-form';
import { EColors, getColor } from 'theme';
import { Body2, Caption } from '../Typography/Typography';

const StyledSelect = styled(Select, {
    shouldForwardProp: prop => prop !== 'disabled',
})<{ disabled?: boolean }>(({ theme: { getColor, EColors, shape }, disabled }) => ({
    height: '50px',
    width: '100%',
    fontSize: '0.875rem',
    backgroundColor: disabled ? getColor(EColors.drWhite) : getColor(EColors.pureWhite),
    pointerEvents: disabled ? 'none' : 'auto',

    '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${getColor(EColors.bizlyOSBorder)}`,
        borderRadius: shape.borderRadius,
    },

    '&.Mui-disabled': {
        color: getColor(EColors.pureBlack),

        '& .MuiSelect-select': {
            color: getColor(EColors.pureBlack),
            '-webkit-text-fill-color': getColor(EColors.pureBlack),
        },

        '& .MuiMenuItem-root.Mui-disabled': {
            color: getColor(EColors.pureBlack),
        },
    },
}));

const StyledFormControl = styled(FormControl)<{ disabled?: boolean }>(({ theme: { getColor, EColors }, disabled }) => ({
    width: '100%',
    backgroundColor: disabled ? getColor(EColors.drWhite) : getColor(EColors.pureWhite),
    borderRadius: '4px',
    pointerEvents: disabled ? 'none' : 'auto',
}));

const StyledInputLabel = styled(InputLabel)(({ theme: { getColor, EColors } }) => ({
    fontSize: '0.875rem',
    lineHeight: '0.875rem',

    '&.MuiFormLabel-root': {
        top: '2px',
        color: getColor(EColors.pureBlack),
    },

    '&.Mui-focused': {
        color: getColor(EColors.pureBlack),
    },

    '&.Mui-disabled': {
        color: `${getColor(EColors.pureBlack)}`,
    },
}));

type SelectFieldProps = {
    options: { value: number | string; label: string }[];
    label: string;
    placeholder?: string;
    emptyMessage?: string;
    error?: FieldError | undefined;
    onChange?: (value: string) => void;
    onBlur?: () => void;
    value: string | number;
    disabled?: boolean;
    required?: boolean;
    name?: string;
};

const SelectField = React.forwardRef<HTMLDivElement, SelectFieldProps>(
    (
        {
            options = [],
            placeholder,
            label,
            error,
            onChange,
            emptyMessage = '',
            disabled = false,
            required = false,
            ...field
        },
        ref
    ) => {
        return (
            <Box>
                <Box display="flex" gap={0.5} marginBottom={1}>
                    <Body2 fontWeight={500}>{label}</Body2>
                    {required && (
                        <Body2 color={EColors.bizlyOSPrimary} fontWeight={500}>
                            *
                        </Body2>
                    )}
                </Box>
                <StyledFormControl fullWidth disabled={disabled}>
                    {!field.value && (
                        <StyledInputLabel shrink={false} sx={{ top: '-3px', color: getColor(EColors.pureBlack) }}>
                            {placeholder ? placeholder : 'Select'}
                        </StyledInputLabel>
                    )}
                    <StyledSelect
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={onChange}
                        {...field}
                        ref={ref}
                        disabled={disabled}
                    >
                        {options.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}

                        {options.length === 0 && !!emptyMessage ? (
                            <MenuItem value={'empty_message'} disabled>
                                {emptyMessage}
                            </MenuItem>
                        ) : null}
                    </StyledSelect>
                </StyledFormControl>
                {!!error && (
                    <Caption size="large" color={EColors.red} fontWeight={500}>
                        {error.message}
                    </Caption>
                )}
            </Box>
        );
    }
);

export default SelectField;
