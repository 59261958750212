import HelpIcon from '@mui/icons-material/Help';
import { Box, styled } from '@mui/material';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { EColors, getColor } from 'theme';
import { InquiryManager } from './InquiryActions/InquiryManager';

const Container = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    border: `1px solid ${getColor(EColors.bizlyOSBorder)}`,
    borderRadius: shape.borderRadius,
    boxSizing: 'border-box',
    padding: spacing(2.5),
}));

export const InquiryAction = () => {
    return (
        <Box display="flex" flexDirection="column" gap={2.5}>
            <Container>
                <InquiryManager />
            </Container>
            <Container>
                <Box display="flex" flexDirection="row" gap={1.25}>
                    <HelpIcon sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                    <Box>
                        <Body1 fontWeight={600}>Got Questions?</Body1>
                        <Body2>
                            Email us at{' '}
                            <a href="mailto:concierge@bizly.com" style={{ color: getColor(EColors.bizlyOSPrimary) }}>
                                concierge@bizly.com
                            </a>
                            . We're here to help!
                        </Body2>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};
