import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, styled } from '@mui/material';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { EColors, getColor } from 'theme';

const InfoContainer = styled(Box)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1.25),
    borderRadius: shape.borderRadius,
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    gap: spacing(1.25),
}));

const InfoContainer2 = styled(Box)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2.5),
    borderRadius: shape.borderRadius,
    backgroundColor: getColor(EColors.pureBlack),
    gap: spacing(1.25),
}));

export const RoomBlockInfo = ({
    checkIn,
    checkOut,
    totalRoomCount,
}: {
    checkIn: string;
    checkOut: string;
    totalRoomCount: number;
}) => {
    return (
        <>
            <InfoContainer>
                <InfoOutlinedIcon fontSize="small" sx={{ color: getColor(EColors.primaryAction) }} />
                <Body2>Add or edit requested guest rooms.</Body2>
            </InfoContainer>
            <InfoContainer2>
                <Box flex={1}>
                    <Body2 color={EColors.pureWhite}>Check In</Body2>
                    <Body1 color={EColors.pureWhite}>{checkIn}</Body1>
                </Box>
                <Box flex={1} borderLeft={`0.5px solid ${getColor(EColors.bizlyOSBorder)}`} paddingLeft={2.5}>
                    <Body2 color={EColors.pureWhite}>Check Out</Body2>
                    <Body1 color={EColors.pureWhite}>{checkOut}</Body1>
                </Box>
                <Box flex={1} borderLeft={`0.5px solid ${getColor(EColors.bizlyOSBorder)}`} paddingLeft={2.5}>
                    <Body2 color={EColors.pureWhite}>Room Nights</Body2>
                    <Body1 color={EColors.pureWhite}>{totalRoomCount}</Body1>
                </Box>
            </InfoContainer2>
        </>
    );
};
