import * as Sentry from '@sentry/react';
import { getInquiryRejectionReasons, rejectProposalForm, sendProposalForm } from 'api';
import { useProposalInquiryStore } from 'providers/proposal-inquiry';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PROPOSAL_QUERIES } from './useProposalsQuery';

const INQUIRY_QUERIES = {
    ACCEPT: 'accept_inquiry',
    REJECT: 'reject_inquiry',
    REJECTION_REASONS: 'rejection_reasons',
} as const;

export const useAcceptInquiry = (venueId: string, proposalId: string) => {
    const queryClient = useQueryClient();
    const updateProposalInquiryForm = useProposalInquiryStore(state => state.updateProposalInquiryForm);

    return useMutation({
        mutationKey: [INQUIRY_QUERIES.ACCEPT, venueId, proposalId],
        mutationFn: async () => await sendProposalForm(venueId, proposalId),
        onSuccess: data => {
            queryClient.invalidateQueries([PROPOSAL_QUERIES.PROPOSAL_INQURY, venueId, proposalId]);
            updateProposalInquiryForm({
                proposal: data.proposal || {},
                inquiry: data.inquiry || {},
            });
        },
        onError: (error: unknown) => {
            console.error('Error accepting inquiry:', error);
            Sentry.captureException(error, {
                tags: { component: 'useAcceptInquiry' },
                extra: { venueId, proposalId },
            });
        },
    });
};

export const useRejectInquiry = (venueId: string, proposalId: string) => {
    const queryClient = useQueryClient();
    const updateProposalInquiryForm = useProposalInquiryStore(state => state.updateProposalInquiryForm);

    return useMutation({
        mutationKey: [INQUIRY_QUERIES.REJECT, venueId, proposalId],
        mutationFn: async (data: { rejectionReason: string; rejectionNotes: string }) => {
            return await rejectProposalForm(venueId, proposalId, data);
        },
        onSuccess: data => {
            queryClient.invalidateQueries([PROPOSAL_QUERIES.PROPOSAL_INQURY, venueId, proposalId]);
            updateProposalInquiryForm({
                proposal: data.proposal || {},
                inquiry: data.inquiry || {},
            });
        },
        onError: (error: unknown) => {
            console.error('Error rejecting inquiry:', error);
            Sentry.captureException(error, {
                tags: { component: 'useRejectInquiry' },
                extra: { venueId, proposalId },
            });
        },
    });
};

export const useInquiryRejectionReasons = () => {
    return useQuery({
        queryKey: INQUIRY_QUERIES.REJECTION_REASONS,
        queryFn: async () => await getInquiryRejectionReasons(),
        onError: (error: unknown) => {
            console.error('Error rejecting inquiry:', error);
            Sentry.captureException(error, {
                tags: { component: 'useRejectInquiry' },
            });
        },
    });
};
