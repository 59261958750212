import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import { Box } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { EColors, getColor } from 'theme';

export const NewInquiryAction = ({
    handleInitiateAccept,
    handleInitiateDecline,
}: {
    handleInitiateAccept: () => void;
    handleInitiateDecline: () => void;
}) => {
    return (
        <>
            <Box display="flex" flexDirection="row" gap={1.25} marginBottom={1.25}>
                <RequestQuoteOutlinedIcon sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                <Body1 fontWeight={600}>New Inquiry - Act Fast!</Body1>
            </Box>
            <Body2 marginBottom={2.5}>
                Other venues are bidding, and first responses win 80% more often. By accepting, you confirm you've
                reviewed all event details. Terms become binding only after the client accepts and signs.
            </Body2>
            <Button fullWidth size="small" sx={{ marginBottom: '10px', height: '40px' }} onClick={handleInitiateAccept}>
                Accept
            </Button>
            <Button fullWidth variant="outline" color="error" sx={{ height: '40px' }} onClick={handleInitiateDecline}>
                Decline Inquiry
            </Button>
        </>
    );
};
