import CircleIcon from '@mui/icons-material/Circle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, styled } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { TFBRequest } from 'components/ProposalForm/EventSpacesForm/utils';
import { map } from 'lodash';
import { useProposalInquiryStore } from 'providers/proposal-inquiry';
import { useState } from 'react';
import { EColors, getColor } from 'theme';
import { tzMoment } from 'utils/moment';

const DATE_FORMAT = 'MMM DD, YYYY';

const BorderedContainer = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    padding: spacing(2.5),
    borderRadius: shape.borderRadius,
    border: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    '& > :last-child': {
        borderBottom: 'none',
        paddingBottom: 0,
    },
}));

const InfoContainer = styled(BorderedContainer)(({ theme: { getColor, EColors } }) => ({
    backgroundColor: getColor(EColors.pureBlack),
    display: 'flex',
    justifyContent: 'space-between',
}));

const BorderedBottomContainer = styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: spacing(2.5),
    paddingTop: spacing(2.5),
}));

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} {...props} />)(
    ({ theme }) => ({
        border: `0.5px solid ${theme.palette.divider}`,
        '&::before': {
            display: 'none',
        },
        borderRadius: theme.shape.borderRadius,
    })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: getColor(EColors.pureBlack), fontSize: 22 }} />}
        {...props}
    />
))(({ theme }) => ({
    height: '64px',
    padding: theme.spacing(2.5),
    '& .MuiAccordionSummary-content': {
        margin: 0,
    },
    '& .MuiAccordionSummary-content > p': {
        fontSize: '1rem',
        fontWeight: '600',
        lineHeight: '24px',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2.5),
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
}));

const Title = styled(Body1)(({ theme: { getColor, EColors, spacing } }) => ({
    paddingBottom: spacing(2.5),
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    fontWeight: 600,
}));

function TextCard({
    title,
    description,
    descriptions = [],
}: {
    title: string;
    description?: string;
    descriptions?: string[];
}) {
    return (
        <Box flex={1} display="flex" flexDirection="column" gap={0.75}>
            <Body2 lineHeight="normal" color={EColors.pureBlack} sx={{ opacity: 0.5 }} fontWeight={500}>
                {title}
            </Body2>
            {description ? (
                <Body1 lineHeight="normal">{description || '-'}</Body1>
            ) : descriptions.length > 0 ? (
                descriptions.map((desc, index) => (
                    <Body1 key={index} lineHeight="normal">
                        {desc || '-'}
                    </Body1>
                ))
            ) : (
                <Body1 lineHeight="normal">-</Body1>
            )}
        </Box>
    );
}

export function EventSpaces() {
    const { eventSpaces, options, venue } = useProposalInquiryStore(state => state.proposalInquiryForm);

    const [expanded, setExpanded] = useState<number | false>(false);

    const handleChange = (panel: number) => (_: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    const getRoomSetup = (id: number | undefined | null) => {
        if (!id) return '-';

        const roomSetup = options?.spaceSetups.find(rs => rs.id === id);

        if (roomSetup) return roomSetup.name;

        return '-';
    };

    const getAvRequests = (ids: number[] | undefined | null) => {
        if (!ids || ids.length === 0) return [];

        const requests: string[] = [];

        ids.forEach(id => {
            const avOption = options?.avOptions.find(av => av.id === id);
            if (!avOption?.name) return;
            requests.push(avOption?.name);
        });

        return requests;
    };

    const getFbRequests = (fbRequests: TFBRequest[] | undefined) => {
        if (!fbRequests || fbRequests.length === 0) return [];

        const requests: string[] = [];

        fbRequests.forEach(request => {
            const fbOption = options?.fbOptions.find(av => av.id === request.fbOptionId);
            if (!fbOption?.name) return;
        });

        return requests;
    };

    return (
        <Box display="flex" flexDirection="column" gap={2.5}>
            <InfoContainer>
                <Body1 fontWeight={500} color={EColors.pureWhite}>
                    # of Requested Spaces
                </Body1>
                <Body1 fontWeight={500} color={EColors.pureWhite}>
                    {eventSpaces?.length || 0}
                </Body1>
            </InfoContainer>
            {map(eventSpaces, (eventSpace, index) => {
                const avRequests = getAvRequests(eventSpace.requestedAvIds);
                const fbRequests = getFbRequests(eventSpace.requestedFb);
                const requestedDate = tzMoment(eventSpace.requestedDate, venue?.timeZone).format(DATE_FORMAT);

                return (
                    <Accordion key={index} expanded={expanded === index} onChange={handleChange(index)}>
                        <AccordionSummary>
                            <Box display="flex" alignItems="center" gap={1.25}>
                                <Body1 fontWeight={500}>{eventSpace.requestedSpaceName || '-'}</Body1>
                                <CircleIcon sx={{ fontSize: 5 }} />
                                <Body2>{`${requestedDate} (${eventSpace?.requestedStartTime} - ${eventSpace?.requestedEndTime})`}</Body2>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box borderBottom={`0.5px solid ${getColor(EColors.bizlyOSBorder)}`} />
                            <BorderedContainer>
                                <Title>Client Details</Title>
                                <BorderedBottomContainer>
                                    <TextCard title="Session" description={eventSpace.requestedSpaceName || '-'} />
                                    <TextCard title="Date" description={requestedDate} />
                                </BorderedBottomContainer>
                                <BorderedBottomContainer>
                                    <TextCard
                                        title="Time"
                                        description={`${eventSpace?.requestedStartTime} - ${eventSpace?.requestedEndTime}`}
                                    />
                                    <TextCard
                                        title="Headcount"
                                        description={eventSpace?.requestedGuests?.toString() || ''}
                                    />
                                </BorderedBottomContainer>
                                <BorderedBottomContainer>
                                    {/*TODO: change the description below with the real value once the API is ready */}
                                    <TextCard title="Preferred Space" description="Private Dining Room" />
                                    <TextCard
                                        title="Space Configuration"
                                        description={getRoomSetup(eventSpace.requestedSetupId)}
                                    />
                                </BorderedBottomContainer>
                                {avRequests.length > 0 && (
                                    <BorderedBottomContainer>
                                        <TextCard title="Audio & Visual Requirements" descriptions={avRequests} />
                                    </BorderedBottomContainer>
                                )}

                                {fbRequests.length > 0 && (
                                    <BorderedBottomContainer>
                                        <TextCard title="Food & Beverage Requirements" descriptions={fbRequests} />
                                    </BorderedBottomContainer>
                                )}
                            </BorderedContainer>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </Box>
    );
}
