import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import { Box } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { useProposalInquiryStore } from 'providers/proposal-inquiry';
import { useNavigate } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import { tzMoment } from 'utils/moment';

export const AcceptedInquiry = ({ venueId, proposalId }: { venueId: string; proposalId: string }) => {
    const navigate = useNavigate();
    const { client, proposal } = useProposalInquiryStore(state => state.proposalInquiryForm);

    const handleBuildProposalClick = () => {
        if (venueId && proposalId) {
            navigate(`/os/venues/${venueId}/proposals/${proposalId}/inquiry-proposal`);
        }
    };

    return (
        <>
            <Box display="flex" flexDirection="row" gap={1.25} marginBottom={1.25}>
                <RequestQuoteOutlinedIcon sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                <Body1 fontWeight={600}>Inquiry Accepted</Body1>
            </Box>
            <Body2 marginBottom={2.5}>
                {`${client?.firstName} ${client?.lastName} accepted the inquiry on ${tzMoment(proposal?.sentAt).format('MMM D, YYYY')}. Submit to client ASAP as first proposals are 80%
                    more likely to win!`}
            </Body2>
            <Button
                fullWidth
                size="small"
                sx={{ marginBottom: '10px', height: '40px' }}
                onClick={handleBuildProposalClick}
            >
                Build Proposal
            </Button>
        </>
    );
};
