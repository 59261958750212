import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { Body2 } from 'components/BizlyOS/Typography/Typography';
import styled from 'styled-components';
import { EColors, getColor } from 'theme';

export const Container = styled(Box)({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
});

export const Body = styled(Box)(({ theme: { spacing } }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
    overflowY: 'auto',
    paddingBottom: spacing(2.5),
}));

const Card = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    padding: spacing(1.25),
    borderRadius: shape.borderRadius,
    border: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(1.25),
}));

const CardRow = styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    gap: spacing(1.25),
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const TotalCard = styled(Card)(({ theme: { getColor, EColors } }) => ({
    backgroundColor: getColor(EColors.pureBlack),
}));

const Title = styled(Body2)(({ theme: { getColor, EColors, spacing } }) => ({
    paddingBottom: spacing(1.25),
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    fontWeight: 500,
}));

export const Devider = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    width: 'calc(100% + 40px)',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translateX(-20px)',
    height: '0.5px',
    backgroundColor: getColor(EColors.bizlyOSBorder),
    marginBottom: spacing(2.5),
}));

const InfoContainer = styled(Box)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    display: 'flex',
    padding: spacing(2.5),
    borderRadius: shape.borderRadius,
    backgroundColor: getColor(EColors.bizlyOSWarning, 0.1),
    gap: spacing(1.25),
}));

export function ProposalSummary() {
    return (
        <Container>
            <Body>
                <Card>
                    <Title>Room Block</Title>
                    <CardRow>
                        <Body2>Rate</Body2>
                        <Body2>$0.00</Body2>
                    </CardRow>
                    <CardRow>
                        <Body2>Occupancy Tax (0%)</Body2>
                        <Body2>$0.00</Body2>
                    </CardRow>
                    <CardRow>
                        <Body2>Resort Fee</Body2>
                        <Body2>$0.00</Body2>
                    </CardRow>
                    <CardRow>
                        <Body2 fontWeight={500}>Room Block Total</Body2>
                        <Body2 fontWeight={500}>$0.00</Body2>
                    </CardRow>
                </Card>
                <Card>
                    <Title>Spaces</Title>
                    <CardRow>
                        <Body2>Rate</Body2>
                        <Body2>$0.00</Body2>
                    </CardRow>
                    <CardRow>
                        <Body2>F&B Minimum</Body2>
                        <Body2>$0.00</Body2>
                    </CardRow>
                    <CardRow>
                        <Body2>Sales Tax (0%)</Body2>
                        <Body2>$0.00</Body2>
                    </CardRow>
                    <CardRow>
                        <Body2>Service Charge (0%)</Body2>
                        <Body2>$0.00</Body2>
                    </CardRow>
                    <CardRow>
                        <Body2>Gratuity (0%)</Body2>
                        <Body2>$0.00</Body2>
                    </CardRow>
                    <CardRow>
                        <Body2 fontWeight={500}>Space Total</Body2>
                        <Body2 fontWeight={500}>$0.00</Body2>
                    </CardRow>
                </Card>
                <TotalCard>
                    <CardRow>
                        <Body2 color={EColors.pureWhite} fontWeight={500}>
                            Proposal Total
                        </Body2>
                        <Body2 color={EColors.pureWhite} fontWeight={500}>
                            $0.00
                        </Body2>
                    </CardRow>
                    <CardRow>
                        <Body2 color={EColors.pureWhite} fontWeight={500}>
                            Deposit (0%)
                        </Body2>
                        <Body2 color={EColors.pureWhite} fontWeight={500}>
                            $0.00
                        </Body2>
                    </CardRow>
                </TotalCard>
                <InfoContainer>
                    <InfoOutlinedIcon fontSize="small" sx={{ color: getColor(EColors.bizlyOSWarning) }} />
                    <Body2>Review and complete all required fields to send your proposal.</Body2>
                </InfoContainer>
            </Body>
            <Devider />
            <Button disabled>Send Proposal</Button>
        </Container>
    );
}
