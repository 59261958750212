import { TProposalForm } from 'components/ProposalForm/types';
import { get, post, put } from '.';

export const authProposalForm = (venueId: number | string, proposalId: number | string, token: string) =>
    get(`venues/${venueId}/proposals/${proposalId}/make-cookie?token=${token}`, { showErrorCode: true });

export const getProposalForm = (venueId: number | string, proposalId: number | string) =>
    get(`venues/${venueId}/proposals/${proposalId}`, { showErrorCode: true }) as Promise<TProposalForm>;

export const putProposalForm = (venueId: number | string, proposalId: number | string, data: unknown) =>
    put(`venues/${venueId}/proposals/${proposalId}`, data) as Promise<TProposalForm>;

export const sendProposalForm = (venueId: number | string, proposalId: number | string) =>
    post(`venues/${venueId}/proposals/${proposalId}/send`);

export const OTHER_REASON_ID = 1;

export const rejectProposalForm = (
    venueId: number | string,
    proposalId: number | string,
    data?: {
        rejectionReason: string;
        rejectionNotes: string;
    }
) => post(`venues/${venueId}/proposals/${proposalId}/reject`, data) as Promise<TProposalForm>;

export const cancelProposalForm = (venueId: number | string, proposalId: number | string) =>
    post(`venues/${venueId}/proposals/${proposalId}/cancel`) as Promise<TProposalForm>;

export const postProposalFormContact = (venueId: number | string, proposalId: number | string, data: unknown) =>
    post(`venues/${venueId}/proposals/${proposalId}/contact`, data) as Promise<TProposalForm>;

export const exportProposals = (eventId: number | string) =>
    get(`events/${eventId}/proposals/export`).then(res => res.url);

export const getInquiryRejectionReasons = () =>
    get(`inquiry-rejection-reasons`) as Promise<{ inquiryRejectionReasons: BizlyAPI.InquiryRejectionReason[] }>;

export const rejectProposalExtension = (proposalId: number | string) =>
    post(`proposals/${proposalId}/reject-extension`);

export type TSpacePOST = Partial<{
    id: number;
    name: string;
    imageUrl: string;
    description: string | null;
    size: Area.Sqft | string | null;
    maxCapacity: number | string | null;
}>;

export const putVenueSpace = ({
    venueId,
    proposalId,
    spaceId,
    data,
}: {
    venueId: number | string;
    proposalId: number | string;
    spaceId: number | string;
    data: TSpacePOST;
}) => put(`venues/${venueId}/proposals/${proposalId}/spaces/${spaceId}`, data) as Promise<TProposalForm>;

export const postVenueSpace = ({
    venueId,
    proposalId,
    data,
}: {
    venueId: number | string;
    proposalId: number | string;
    data: TSpacePOST;
}) => post(`venues/${venueId}/proposals/${proposalId}/spaces`, data) as Promise<TProposalForm>;
