import { Box, styled } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { BorderedContainer } from 'components/BizlyOS/GridContainer';
import { TitleTab, TitleTabs } from 'components/BizlyOS/Tabs';
import TitledContainer from 'components/BizlyOS/TitledContainer';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import PiggyBank from 'images/bizlyOS/empty/piggy_bank.svg?react';
import { useProposalInquiryStore } from 'providers/proposal-inquiry';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { INQUIRY_OVERVIEW_PATH } from '../../InquirySubNavigation';
import { InquiryStatus } from '../../utils';
import { EventSpaces } from './EventSpaces';
import { FinalDetails } from './FinalDetails';
import { RoomBlock } from './RoomBlock/RoomBlock';
import { TaxAndFees } from './TaxAndFees';
import { Terms } from './Terms';

const ROOMBLOCK_ID = 'room-block';
const EVENT_SPACE_ID = 'event-space';
const TAX_AND_FEES_ID = 'tax-and-fees';
const TERMS_ID = 'terms';
const FINAL_DETAILS_ID = 'final-details';

const TabOptions = [
    { id: ROOMBLOCK_ID, label: 'Room Block', Element: RoomBlock },
    { id: EVENT_SPACE_ID, label: 'Spaces', Element: EventSpaces },
    { id: TAX_AND_FEES_ID, label: 'Taxes & Fees', Element: TaxAndFees },
    { id: TERMS_ID, label: 'Terms', Element: Terms },
    { id: FINAL_DETAILS_ID, label: 'Final Details', Element: FinalDetails },
];

const Container = styled(({ ...props }) => <BorderedContainer borderWidth="small" {...props} />)(
    ({ theme: { spacing } }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: spacing(2.5),
    })
);

const BodyContainer = styled(Box)({
    width: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

const ReviewButton = styled(Button)({ height: '40px' });

const InquiryNotAccepted = () => {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    return (
        <Container>
            <BodyContainer>
                <PiggyBank />
                <Body1 size="large" lineHeight="200%" fontWeight={700}>
                    Ready to submit your proposal?
                </Body1>
                <Body2>
                    Review and accept the inquiry first to unlock the proposal builder, client messaging, and document
                    sharing features.
                </Body2>
            </BodyContainer>
            <Link to={`/os/venues/${venueId}/proposals/${proposalId}/` + INQUIRY_OVERVIEW_PATH}>
                <ReviewButton>Review Inquiry</ReviewButton>
            </Link>
        </Container>
    );
};

export function InquiryProposalBody() {
    const { proposalInquiryForm } = useProposalInquiryStore();
    const { inquiry } = proposalInquiryForm;

    const [tabIndex, setTabIndex] = useState(0);

    if (inquiry?.status !== InquiryStatus.ACCEPTED) {
        return <InquiryNotAccepted />;
    }

    const visibleTabIds = [
        TAX_AND_FEES_ID,
        TERMS_ID,
        FINAL_DETAILS_ID,
        ...(proposalInquiryForm.eventSpaces?.length ? [EVENT_SPACE_ID] : []),
        ...(proposalInquiryForm.guestRooms?.length ? [ROOMBLOCK_ID] : []),
    ];

    const allowedTabs = [ROOMBLOCK_ID];

    const handleTabChange = (_: React.SyntheticEvent, newIndex: number) => {
        setTabIndex(newIndex);
    };

    return (
        <TitledContainer
            borderWidth="small"
            leftElement={
                <TitleTabs value={tabIndex} onChange={handleTabChange}>
                    {TabOptions.map(
                        (tab, index) =>
                            visibleTabIds.includes(tab.id) && (
                                <TitleTab
                                    disabled={!allowedTabs.includes(tab.id)}
                                    key={index}
                                    label={tab.label}
                                    value={index}
                                />
                            )
                    )}
                </TitleTabs>
            }
        >
            {React.createElement(TabOptions[tabIndex].Element)}
        </TitledContainer>
    );
}
