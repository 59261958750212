import { Box, styled } from '@mui/material';
import InquiryRoutes from 'components/BizlyOS/Proposals/InquiryRoutes';
import InquirySubNavigation from 'components/BizlyOS/Proposals/InquirySubNavigation';
import { ProposalInquryHeader } from 'components/BizlyOS/Proposals/ProposalInquryHeader';
import { Spinner } from 'components/Spinner';
import { useGetProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { useProposalInquiryStore } from 'providers/proposal-inquiry';
import { useParams } from 'react-router-dom';

const Container = styled(Box)({
    height: '100%',
    maxHeight: '100%',
    boxSizing: 'border-box',
    overflowX: 'auto',
});

const HEADER_HEIGHT = '75px';

const HeaderContainer = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(2.5),
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    boxSizing: 'border-box',
    height: HEADER_HEIGHT,
    minWidth: '840px',
}));

const BodyContainer = styled(Box)(({ theme }) => ({
    height: `calc(100% - ${HEADER_HEIGHT})`,
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: 'minmax(180px,220px) minmax(540px,1fr) minmax(200px,360px)',
    gridTemplateRows: '1fr',
    gap: theme.spacing(2.5),
    padding: theme.spacing(2.5),
}));

export const ProposalInqury = () => {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const setProposalInquiryForm = useProposalInquiryStore(state => state.setProposalInquiryForm);

    const { data: proposalInquiryForm, isLoading } = useGetProposalInquiry(venueId, proposalId, data => {
        if (data) setProposalInquiryForm(data);
    });

    if (isLoading || !proposalInquiryForm) {
        return (
            <Box p={10} display="flex" alignItems="center" justifyContent="center">
                <Spinner />
            </Box>
        );
    }

    return (
        <Container>
            <HeaderContainer>
                <ProposalInquryHeader />
            </HeaderContainer>
            <BodyContainer>
                <InquirySubNavigation />
                <InquiryRoutes />
            </BodyContainer>
        </Container>
    );
};
