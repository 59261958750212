import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, styled } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { Body1 } from 'components/BizlyOS/Typography/Typography';
import { useProposalInquiryStore } from 'providers/proposal-inquiry';
import { useState } from 'react';
import { EColors, getColor } from 'theme';
import { tzMoment } from 'utils/moment';
import { RoomBlockInfo } from './RoomBlockInfo';
import { RoomBlockSideDrawer } from './RoomBlockSideDrawer';
import { RoomContent } from './RoomContent';

const DATE_FORMAT = 'MMM D, YYYY';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} {...props} />)(
    ({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&::before': {
            display: 'none',
        },
        borderRadius: theme.shape.borderRadius,
    })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: getColor(EColors.pureBlack), fontSize: 22 }} />}
        {...props}
    />
))(({ theme }) => ({
    height: '64px',
    padding: theme.spacing(2.5),
    '& .MuiAccordionSummary-content': {
        margin: 0,
    },
    '& .MuiAccordionSummary-content > p': {
        fontSize: '1rem',
        fontWeight: '600',
        lineHeight: '24px',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2.5),
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
}));

export function RoomBlock() {
    const { guestRooms, venue } = useProposalInquiryStore(state => state.proposalInquiryForm);
    const totalRooms = guestRooms?.reduce((acc, { requestedGuests }) => acc + (requestedGuests ?? 0), 0);

    const [expanded, setExpanded] = useState<number[]>([]);

    const [selectedRoomBlockIndex, setSelectedRoomBlockIndex] = useState<number>();
    const [isActionDrawerOpen, setIsActionDrawerOpen] = useState<boolean>(false);

    const handleChange = (panel: number) => (_: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(prev => {
            if (newExpanded) {
                return [...prev, panel];
            }
            return prev.filter(index => index !== panel);
        });
    };

    const onDrawerClose = () => {
        setSelectedRoomBlockIndex(undefined);
        setIsActionDrawerOpen(false);
    };

    if (!guestRooms || guestRooms?.length === 0) return null;

    return (
        <>
            <Box display="flex" flexDirection="column" gap={2.5}>
                <RoomBlockInfo
                    checkIn={tzMoment(guestRooms[0].date, venue?.timeZone).format(DATE_FORMAT)}
                    checkOut={tzMoment(guestRooms[guestRooms.length - 1].date, venue?.timeZone).format(DATE_FORMAT)}
                    totalRoomCount={totalRooms || 0}
                />

                {guestRooms.map((room, index) => {
                    return (
                        <Accordion expanded={expanded.includes(index)} onChange={handleChange(index)}>
                            <AccordionSummary>
                                <Box display="flex" alignItems="center" gap={1.25}>
                                    <CheckCircleIcon fontSize="medium" color="disabled" />
                                    <Body1 fontWeight={500}>
                                        {tzMoment(room.date, venue?.timeZone).format(DATE_FORMAT)}
                                    </Body1>
                                    <CircleIcon sx={{ fontSize: 6 }} />
                                    <Body1>{`${room.requestedGuests} Guest Rooms`}</Body1>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RoomContent
                                    rooms={[
                                        {
                                            id: room.date,
                                            type: room.proposedRoomName || 'Single',
                                            qyt: room.proposedRoomCount || room.requestedGuests || 0,
                                            dailyRate: room.proposedRoomRate || 0,
                                            onAction: () => {
                                                setSelectedRoomBlockIndex(index);
                                                setIsActionDrawerOpen(true);
                                            },
                                        },
                                    ]}
                                />
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Box>
            <RoomBlockSideDrawer
                drawerOpen={isActionDrawerOpen}
                onClose={onDrawerClose}
                room={selectedRoomBlockIndex ? guestRooms[selectedRoomBlockIndex] : undefined}
            />
        </>
    );
}
