import { styled } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

export const GridContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
}));

export const RowContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    padding: theme.spacing(2.5),
    boxSizing: 'border-box',
    gap: theme.spacing(2.5),
}));

export const BorderedContainer = styled(Box, {
    shouldForwardProp: prop => prop !== 'borderWidth' && prop !== 'padding',
})<{ borderWidth?: 'default' | 'small' } & BoxProps>(
    ({ theme: { getColor, EColors, shape }, padding = 0, borderWidth = 'default' }) => ({
        height: '100%',
        border: `1px solid ${getColor(EColors.bizlyOSBorder)}`,
        borderWidth: borderWidth === 'small' ? '0.5px' : '1px',
        borderRadius: shape.borderRadius,
        boxSizing: 'border-box',
        padding: padding,
    })
);
