import { Box, styled } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { SideDrawer } from 'components/BizlyOS/SideDrawer/SideDrawer';
import { TGRBooking } from 'components/ProposalForm/types';

const ActionButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
}));

interface DrawerActionButtonsProps {
    onCancel: () => void;
    onSubmit: () => void;
    isSubmitting?: boolean;
    isValid?: boolean;
}

const DrawerActionButtons = ({
    onCancel,
    onSubmit,
    isSubmitting = false,
    isValid = true,
}: DrawerActionButtonsProps) => {
    return (
        <ActionButtonContainer>
            <Button variant="secondary" disableElevation onClick={onCancel}>
                Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting || !isValid} onClick={onSubmit}>
                {isSubmitting ? 'Submitting...' : 'Save'}
            </Button>
        </ActionButtonContainer>
    );
};

type RoomBlockSideDrawerProps = {
    drawerOpen: boolean;
    onClose: () => void;
    room?: TGRBooking;
};

export function RoomBlockSideDrawer({ drawerOpen, onClose }: RoomBlockSideDrawerProps) {
    return (
        <SideDrawer
            drawerOpen={drawerOpen}
            onClose={onClose}
            footer={<DrawerActionButtons onCancel={onClose} onSubmit={onClose} />}
            title="Guest Room Proposal"
        ></SideDrawer>
    );
}
