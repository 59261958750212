import { Box, styled } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { Body1 } from 'components/BizlyOS/Typography/Typography';

const RoomBlockRow = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: spacing(2.5),
    borderTop: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
}));

const RoomBlockCell = styled(Body1)({
    flex: 1,
    lineHeight: 'normal',
});

const RoomBlockHeaderCell = styled(RoomBlockCell)({
    fontWeight: 500,
});

type RoomContentProps = {
    rooms: { id: string; type: string; qyt: number; dailyRate: number; onAction: () => void }[];
};

export const RoomContent = ({ rooms = [] }: RoomContentProps) => {
    return (
        <>
            <RoomBlockRow>
                <RoomBlockHeaderCell>Room Type</RoomBlockHeaderCell>
                <RoomBlockHeaderCell>QTY</RoomBlockHeaderCell>
                <RoomBlockHeaderCell>Daily Rate</RoomBlockHeaderCell>
                <RoomBlockHeaderCell>Total</RoomBlockHeaderCell>
                <Box width={90} />
            </RoomBlockRow>
            {rooms.map(room => (
                <RoomBlockRow>
                    <RoomBlockCell>{room.type}</RoomBlockCell>
                    <RoomBlockCell>{room.qyt}</RoomBlockCell>
                    <RoomBlockCell>${room.dailyRate}</RoomBlockCell>
                    <RoomBlockCell>${room.qyt * room.dailyRate}</RoomBlockCell>
                    <Button variant="text" sx={{ height: '20px', width: '90px', padding: 0 }} onClick={room.onAction}>
                        Add Details
                    </Button>
                </RoomBlockRow>
            ))}
        </>
    );
};
